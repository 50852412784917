/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";
//@import "~ngx-toastr/toastr.css";
// import Quill styles in styles.scss
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";
@import "~trix/dist/trix.css";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Noto Sans", sans-serif !important;
  scroll-behavior: smooth !important;
}

@mixin blueCloseButton {
  background: rgba(118, 79, 219, 0.2);
  border-radius: 8px;
  width: 48px;
  height: 48px;
  padding: 18px;

  img {
    top: 36px;
    right: 26px;
  }
}

html {
  scroll-behavior: smooth !important;
}

.lh1 {
  line-height: 1 !important;
}

.errorMessage {
  color: red !important;
  font-size: 12px !important;
}

.loader {
  margin: 0 auto !important;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #764fdb;
  width: 70px;
  height: 70px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

// .loader {
//   // position: absolute;
//   margin-top: 200px;
//   // margin-bottom: 345px;
//   // margin-left: 50%;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   width: 100px;
//   height: auto;
//   opacity: 0.5;
//   animation: blink-animation 1s ease-in-out infinite alternate;
//   img{
//     height: 70px;
//     width: 100px;
//   }
// }
@keyframes blink-animation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.1;
  }
}

.dashboard-ov .dashboard-container__wrap:last-child {
  margin-left: 0px !important;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.c1p {
  margin-left: 20px;
}

.dlc {
  color: white;
  font-weight: 600;
  font-size: 13px;
}

.ng-select .ng-select-container {
  min-height: 38px !important;
  max-height: auto !important;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  top: 8px !important;
  //font-weight: 600;
  // font-size: 15px;
  //color: #32363a;

  font-size: 14px;
  // font-weight: 600;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  margin-top: 4px !important;
}

.ng-select.ng-select-opened .ng-select-container {
  z-index: 1 !important;
}

.ng-dropdown-panel {
  z-index: 999 !important;
}

.custom-control-label {
  font-size: 12px !important;
}

.ng-value-label {
  font-size: 14px;
}

.ng-placeholder {
  font-size: 12px !important;
}

.bf1 {
  color: #00aeef;
  font-size: 12px;
  font-weight: 700;
}

.r1 {
  // color: #00adf2;
  // margin-left: 400px;
  margin-top: 30px;
  text-align: center !important;
}

.clear-all {
  border: none;
  background-color: #fff;
}

th {
  font-weight: 700;
}

.editPanel {
  top: 60px;
  right: 8px;
  cursor: pointer !important;
}

body {
  font: icon;
}

#toast-container>.toast-success {
  background-image: none;
  background-color: green;
  color: white;
}

#toast-container>.toast-error {
  background-image: none;
  background-color: red;
  color: white;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .mover {
    height: 350px !important;
    overflow-y: auto;
  }
}

/* For 480 Resolution */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {

  /* STYLES GO HERE */
  .mover {
    height: 350px !important;
    overflow-y: auto;
  }
}

/* For 640 Resolution */
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
  .mover {
    height: 350px !important;
    overflow-y: auto;
  }
}

/* For 1366 Resolution */
@media only screen and (min-width: 1030px) and (max-width: 1366px) {
  .mover {
    height: 350px !important;
    overflow-y: auto;
  }
}

.new-BackgroundD {
  background: #f8faff !important;
}

.new__INput__field {
  padding: 12px 42px !important;
  height: 40px !important;
  min-height: 40px !important;
  border: 1px solid #919292 !important;
  border-radius: 8px !important;
  font-family: "Noto Sans", sans-serif !important;

  &::placeholder {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    color: #87898a !important;
    font-family: "Noto Sans", sans-serif !important;
  }

  &:focus {
    border-color: #764fdb !important;
  }
}

.new-hoverR:hover {
  box-shadow: none !important;
}

.user__HeadingG {
  font-weight: 600 !important;
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.new__focus {
  font-size: 12px !important;
}

.new__focus:focus {
  border-color: #85b7d9 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-size: 12px !important;
}

.new__arrow__weiGht {
  .ng-select .ng-arrow-wrapper .ng-arrow {
    background: url(./assets/img/black-arrow-wrapper.svg) no-repeat !important;
    height: 6px !important;
    width: 15px !important;
    border: none !important;
    margin: -2px -10px 3px 0 !important;
    background-size: contain !important;
  }
}

.new__font__size {
  font-size: 12px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
}

.back__routerLink {
  color: #00acf5 !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.disable__Background {
  background-color: #f9f9f9 !important;
}

.cke_top {
  display: none !important;
}

.content-wrapper {
  padding-left: 20px !important;
}

.figma_blue_button {
  background: #ffffff;
  border: 1.5px solid #764fdb;
  // box-shadow: 0px 0px 23px rgb(0 0 0 / 7%);
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #764fdb;
  text-transform: capitalize;
  height: 40px !important;
  padding: 10px 40px !important;
  cursor: pointer;

  &:focus {
    outline: 0 !important;
  }
}

.blue_background {
  background: #764fdb !important;
}

.pointer {
  cursor: pointer !important;
}

// .dashboard-info {
//   background: #ffffff !important;
// }
.discard_red_button {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #fe5454;
  background: #ffffff;
  border: 2px solid #fe5454;
  border-radius: 8px;
  text-transform: capitalize;
  padding: 8px 30px;
  cursor: pointer;

  @media only screen and (max-width: 1300px) {
    font-size: 12px;
    padding: 8px 20px;
  }

  &:focus {
    outline: 0 !important;
  }
}

.plus-icon-button {
  cursor: pointer;
  border: 0;
  padding: 5px 9px;
  box-shadow: 0 2px 9px #00000018;
  border-radius: 26px;
  background: #764fdb;
}

.figma_blue_color {
  color: #764fdb !important;
}

// .new_table_heading {
//   font-style: normal !important;
//   font-weight: 600 !important;
//   font-size: 14px !important;
//   line-height: 18px !important;
//   color: #2f3031 !important;
// }
// .new_table_data {
//   font-style: normal !important;
//   font-weight: 500 !important;
//   font-size: 12px !important;
//   line-height: 18px !important;
//   color: #2f3031 !important;
// }
.new_figma_background {
  background: #f6f7fb !important;
}

.new_figma_plus_blue_btn {
  background: #764fdb !important;
  cursor: pointer;
  border: 0;
  padding: 10px;
  box-shadow: 0 2px 9px #00000018;
  border-radius: 26px;
  // color: #fff;
  height: 35px;
  cursor: pointer;

  img {
    height: 15px;
    width: 15px;
    margin-top: -3px;
  }

  &:focus {
    outline: 0 !important;
  }
}

.new_figma_delete_red_btn {
  background-color: #fe5454 !important;
  padding: 3px 8px !important;
  border-color: transparent;
  border-radius: 50%;
  cursor: pointer;

  img {
    height: 15px;
    width: 15px;
    margin-top: -2px;
  }
}

.new_figma_blue_btn {
  background: rgba(118, 79, 219, 0.1) !important;
  border-radius: 8px !important;
  border: transparent !important;
  cursor: pointer !important;
  height: 48px;
  width: 48px;

  img {
    width: 16px;
    height: 15px;
  }

  &:focus {
    outline: 0 !important;
  }
}

.figma_background_blue_btn {
  cursor: pointer;
  background: #764fdb;
  box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  border: 0;
  text-transform: capitalize !important;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  text-align: center !important;
  padding: 12px 24px;

  &:focus {
    outline: 0 !important;
  }

  &:hover {
    color: white !important;
  }
}

// figma tabs & table global styles
.figma_single_tabs_heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #2f3031;
}

.figma__border__bottom {
  border-bottom: 8px solid white;

  &:nth-last-child(1) {
    border-bottom: 0;
  }
}

.figma__Table_styles {
  thead tr {
    background: #f6f7fb !important;

    th {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #2f3031;
      padding: 16px 0 16px 0 !important;

      // &:nth-child(1) {
      //   // border-radius: 16px 0 0 16px !important;
      // }

      // &:nth-last-child(1) {
      //   // border-radius: 0 16px 16px 0 !important;
      // }
    }
  }

  tbody {
    tr {
      background: #ffffff;
      // border: 2px solid rgba(214, 202, 244, 1);
      border: 2px solid rgba(0, 0, 0, 0.1);
      // border-bottom: 1px solid #f4effe !important;
      border-radius: 16px;

      td {
        font-weight: 500;
        font-size: 14px !important;
        line-height: 18px;
        color: #2f3031;
        padding: 11px !important;

        p,
        span,
        label {
          font-weight: 500;
          font-size: 14px !important;
          line-height: 18px;
          color: #2f3031;
        }
      }
    }

    &:nth-last-child(1) {
      border-bottom: 1px solid #f4effe !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .div__table__main {
    width: 1300px !important;
    max-width: 1300px !important;
    padding-left: 0 !important;
  }
}

// figma tabs & table global styles
.approved_tipped {
  display: inline-block;
  margin-left: 2px;
  color: black !important;
  font-size: 10px;
  padding: 5px;
  cursor: pointer;
  margin-bottom: 5px;
  border: 1px solid gray;
}

.figma__Inputs {
  background: #f5f5f5;
  border-radius: 8px;
  height: 40px;
  border: transparent !important;
  padding: 12px !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #2f3031;
  box-shadow: none !important;

  &.ng-invalid.ng-touched {
    border: 1px solid red !important;

    &:focus {
      border-color: #764fdb;
    }
  }

  &.ng-valid {
    border: none !important;
  }

  &::placeholder {
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: rgb(47, 48, 49, 0.5) !important;
  }

  &:focus {
    box-shadow: none !important;
    border: 0 !important;
    outline: 0 !important;
  }
}

.figma__Table_styles_manage thead tr th {
  padding: 16px 10px !important;
}

.figma__textArea {
  padding: 16px !important;
  background: #f5f5f5 !important;
  border-radius: 8px;
  border: transparent !important;
  resize: none;
  font-size: 14px !important;

  &:focus {
    outline: none !important;
  }

  &::placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #bababb;
  }
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.figma__input__styles {
  .ng-invalid.ng-touched {
    border: 1px solid red !important;

    &:focus {
      border-color: #764fdb;
    }
  }

  .ng-valid {
    border: none !important;
  }

  input {
    background: #f5f5f5 !important;
    border-radius: 8px !important;
    border: transparent !important;
    height: 42px !important;
    min-height: 42px !important;
    font-size: 14px !important;
    font-weight: 600 !important;

    &::placeholder {
      font-family: "Noto Sans", sans-serif !important;
      font-weight: 500 !important;
      font-size: 12px !important;
      line-height: 16px !important;
      color: rgba(47, 48, 49, 0.5) !important;
    }
  }
}

.figma__input__only {
  background: #f5f5f5 !important;
  border-radius: 8px !important;
  border: transparent !important;
  height: 42px !important;
  min-height: 42px !important;
  font-size: 12px !important;

  &::placeholder {
    font-family: "Noto Sans", sans-serif !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: rgba(47, 48, 49, 0.5) !important;
  }
}

.figma__multi_select {
  .ng-select .ng-select-container {
    caret-color: transparent !important;
    border: transparent !important;
    background-color: #f5f5f5 !important;
    border-radius: 8px !important;
    min-height: 40px !important;
    height: auto !important;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    padding-top: 0 !important;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
    padding-bottom: 0 !important;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    margin-top: 3px !important;
  }

  .p-multiselect {
    background: #f5f5f5;
    border: 0;
    outline: 0;
    height: 40px;
    padding: 12px;
    border-radius: 8px;
    width: 100%;

    .p-multiselect-label {
      // display: none;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 16px !important;
      color: #87898a !important;
      font-family: "Noto Sans", sans-serif !important;

      &.p-placeholder {
        display: block !important;
        padding: 0px;
        font-size: 12px;
      }
    }

    .p-multiselect-trigger {
      position: relative;

      .p-multiselect-trigger-icon {
        &::before {
          content: '';
          background: url(../src/assets/Images/select-down-arrow.svg) no-repeat !important;
          // transform: rotate(180deg);/
          position: absolute;
          width: 20px;
          height: 20px;
          top: 0px;
        }
      }
    }

    .p-multiselect-close {
      position: relative;

      &:hover {
        background: transparent !important;
      }

      .p-multiselect-close-icon {
        &::before {
          content: "";
          background: url(../src/assets/Images/x-close.svg) no-repeat;
          position: absolute;
          width: 20px;
          height: 20px;
          top: 5px;
          left: 5px;
        }
      }
    }

    .p-multiselect-filter-container {
      position: relative;

      .p-multiselect-filter-icon {
        &::before {
          content: '';
          background: url(../src/assets/Images/search-icon.svg) no-repeat;
          position: absolute;
          width: 20px;
          height: 20px;
          top: 0px;
          left: -25px;
        }
      }
    }

    .p-checkbox {
      .p-checkbox-box {
        border: 1px solid #9AA3B2;
        border-radius: 5px;

        &.p-highlight {
          background: #764fdb;
        }

        .p-checkbox-icon {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            background: url(../src/assets/Images/white-check.svg) no-repeat;
            width: 20px;
            height: 20px;
            top: -4px;
            left: -6px;
          }
        }
      }
    }

    .p-multiselect-items .p-multiselect-item:focus {
      &:focus {
        box-shadow: none;
      }
    }
  }
}

.figma__ng__select {
  .ng-select {
    &.ng-invalid.ng-touched {
      border: 1px solid red !important;
      border-radius: 8px;

      &:focus {
        border-color: #764fdb;
      }
    }

    &.ng-valid {
      border: none !important;
    }
  }

  &.new__Phoe__parttttttt {
    .ng-select {
      &.ng-invalid.ng-touched {
        border: 1px solid red !important;
        border-radius: 8px 0px 0px 8px;

        &:focus {
          border-color: #764fdb;
        }
      }

      &.ng-valid {
        border: none !important;
      }
    }
  }

  .ng-select.ng-select-disabled .ng-select-container .ng-value-container .ng-value {
    cursor: not-allowed !important;
  }

  .ng-select.ng-select-disabled .ng-select-container {
    cursor: not-allowed !important;
  }

  ng-select:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    color: #2f3031 !important;
    // z-index: 0 !important;
  }

  .ng-select .ng-clear-wrapper {
    display: none;
  }

  .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    box-shadow: none !important;
    border-color: none !important;
  }

  .ng-select.ng-select-single .ng-select-container {
    height: 40px !important;
    border-radius: 8px !important;
    background: #f5f5f5 !important;
    border: transparent !important;
    z-index: 0 !important;
  }

  .ng-select .ng-select-container {
    min-height: 42px !important;
    height: auto !important;
    border-radius: 8px !important;
    background: #f5f5f5 !important;
    border: transparent !important;
  }

  .ng-select .ng-select-container .ng-value-container .ng-placeholder {
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 16px;
    color: rgb(47, 48, 49, 0.5) !important;
  }

  .ng-select .ng-select-container .ng-value-container .ng-input>input {
    caret-color: transparent !important;
    border-radius: 8px !important;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .ng-select .ng-arrow-wrapper {
    margin-right: 6px;
  }

  .ng-select .ng-arrow-wrapper .ng-arrow {
    background: url(./assets/img/blue-down-wrapper.svg) no-repeat !important;
    height: 6px !important;
    width: 15px !important;
    border: none !important;
    margin: -4px -15px 2px 0 !important;
    background-size: contain !important;
  }

  .ng-select.ng-select-opened>.ng-select-container .ng-arrow {
    transform: rotate(180deg) !important;
    right: 5px !important;
  }
}

.add_another_job_button {
  background: #ffffff;
  border: 1px solid #764fdb;
  box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #764fdb;
  text-transform: capitalize;
}

.figma__label {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #2f3031;
  margin-bottom: 6px !important;
}

.figma_close_btn {
  background: rgb(118, 79, 219, 0.1);
  border-radius: 8px;
  height: 48px;
  width: 48px;
  position: relative;
  cursor: pointer;
  border: 0 !important;
  padding: 17px !important;

  img {
    position: absolute;
    top: 17px;
    left: 17px;
  }

  &:focus {
    outline: 0 !important;
  }
}

.errorRequired {
  color: red !important;
}

.priority_radios_mains {
  .gs-grouped-fileds .ui label:before {
    border: 1.5px solid #764fdb !important;
  }

  .gs-grouped-fileds .ui.radio input:checked~label:after {
    background-color: #764fdb !important;
  }
}

/* ===== Scrollbar CSS ===== */

/* Firefox */
// * {
//   scrollbar-width: auto;
//   scrollbar-color: rgb(118, 79, 219, 0.7) #ffffff;
// }

// /* Chrome, Edge, and Safari */
// *::-webkit-scrollbar {
//   width: 12px;
// }

// *::-webkit-scrollbar-track {
//   background: #ffffff;
// }

// *::-webkit-scrollbar-thumb {
//   background-color: rgb(118, 79, 219, 0.7);
//   border-radius: 10px;
//   border: 3px solid #ffffff;
// }

.div__table__main {
  .divTable__head {
    background: #f6f7fb;
    padding: 16px 110px;

    .cell {
      // width: 167px;
      font-weight: 600 !important;
      font-size: 14px !important;
      line-height: 20px !important;
      // text-align: center;
      color: #2f3031;

      // &:nth-child(1) {
      //   width: 200px !important;
      // }

      // &:nth-child(2) {
      //   width: 175px;
      // }

      // &:nth-child(3) {
      //   // padding-left: 7px !important;
      //   width: 100px !important;
      // }

      // &:nth-child(4) {
      //   width: 234px !important;
      // }

      // &:nth-child(5) {
      //   width: 185px !important;
      //   padding-left: 30px;
      // }
    }
  }

  .row__bodyy {
    background: #ffffff;
    border: 1px solid #f4effe;
    border-radius: 16px;
    padding: 16px 110px;
    align-items: center !important;

    .cell {
      // width: 150px !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 20px !important;
      color: #2f3031 !important;

      // width: 175px !important;
      // &:nth-child(1) {
      //   width: 200px !important;
      // }

      // &:nth-child(2) {
      //   width: 175px !important;
      // }

      // &:nth-child(3) {
      //   width: 100px !important;
      //   // padding-left: 13px !important;
      // }

      // &:nth-child(4) {
      //   width: 234px !important;
      // }

      // &:nth-child(5) {
      //   width: 180px !important;
      //   padding-left: 30px;
      // }
    }
  }
}

.system_field_tiles {
  background: #f6f7fb !important;
  border-radius: 16px !important;
  padding: 14px 0 !important;
  cursor: pointer;

  h4 {
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 28px !important;
    text-align: center !important;
    color: #2f3031 !important;
  }
}

.system_field_heading {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #2f3031;
}

.related_heading {
  margin-top: 32px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #764fdb;
}

.plus_add {
  margin-left: 13px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #764fdb;
  cursor: pointer;
}

.no-cursor {
  cursor: not-allowed !important;
}

.thirtyFive_entries {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 25px !important;
  color: #23ac00 !important;
  margin-bottom: 24px !important;
}

.shortt_navv {
  background: rgb(118, 79, 219, 0.1);
  border-radius: 50%;
  text-align: center !important;
  margin-left: 7px;
  width: 26px;
  height: 26px;
  margin-bottom: 10px;

  .shortt_navvP {
    font-weight: 700;
    font-size: 14px;
  }
}

.figma_search_bar_input {
  width: 444px !important;
  padding: 12px 46px !important;

  &::placeholder {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    color: #87898a !important;
  }
}

.responsive__div__table {
  overflow-x: scroll !important;
}

.job_approval_table_main::-webkit-scrollbar {
  display: none !important;
}

.multi_select_height {
  .ng-select .ng-select-container {
    min-height: 40px !important;
    height: 40px !important;
  }
}

.refCheck_border_bottom_line {
  border-bottom: 1px dashed #dae1ef !important;
}

.newProfile_border_bottom {
  border-bottom: 1px solid #dae1ef !important;
}

.add__Text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #764fdb;
  margin-left: 9px;
  cursor: pointer;
}

.figma_calender_input {
  .ng-invalid.ng-touched {
    .p-inputtext {
      border: 1px solid red !important;
    }

    &:focus {
      border-color: #764fdb;
    }
  }

  .ng-valid {
    border: none !important;
  }

  .p-calendar {
    .p-calendar-w-btn .p-datepicker-trigger {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border-top-right-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
    }

    ::placeholder {
      font-weight: 500 !important;
      color: #999 !important;
    }

    .p-inputtext {
      min-height: 40px !important;
      height: 40px !important;
      border-radius: 8px !important;
      background: #e7e5e5;
      border-radius: 8px !important;
      border: transparent !important;
    }
  }

  .calender__icon {
    top: 36px;
    right: 24px;
  }

  p-calender {
    position: relative;
  }

  // .p-calendar {
  //   // width: inherit !important;
  // }
  .p-calendar .p-inputtext {
    min-height: 40px !important;
    height: 42px !important;
    border-radius: 8px !important;
    background: #f5f5f5 !important;
    border: transparent !important;
    font-weight: 600 !important;
    cursor: default !important;
    font-size: 14px !important;

    &::placeholder {
      font-weight: 500 !important;
      font-size: 12px !important;
      line-height: 16px !important;
      color: rgb(47, 48, 49, 0.5) !important;
    }
  }

  .p-datepicker-group-container {
    .pi-chevron-left:before {
      content: "⮜" !important;
    }

    .pi-chevron-right:before {
      content: "⮞" !important;
    }
  }

  img {
    position: absolute;
    top: 34px;
    right: 24px;
  }

  .p-button {
    color: #ffffff;
    background: url(./assets/Images/calender-icon.svg) 100% no-repeat !important;
    border: 0 !important;
    background-color: #f5f5f5 !important;
    border-radius: 0 8px 8px 0 !important;

    // &:hover {
    //   background: url(./assets/Images/calender-icon.svg) 100% no-repeat !important;
    //   background-color: #f5f5f5 !important;
    // }

    &:focus {
      box-shadow: none !important;
    }
  }

  .p-calendar .p-datepicker {
    left: -100px !important;
    // z-index: 0 !important;
    min-width: 250px;
    // width: 250px;
  }

  .figma_calender_iinnput .p-calendar .p-datepicker {
    left: -250px !important;
  }

  .calender__ml {
    .p-calendar {
      width: 100% !important;
    }

    .p-calendar .p-datepicker {
      left: 0 !important;
      // width: 100%;
      min-width: 250px;
      width: 250px;
      // z-index: 0 !important;
    }
  }

  ::ng-deep {
    .pi-calendar {
      display: none !important;
      background: #000000;
    }

    .pi-calendar:before {
      content: none !important;
    }

  }

  .p-button.p-button-icon-only {
    position: absolute;
    right: 16px;
    top: 1px;
  }

  .p-datepicker .p-datepicker-header {
    padding: 0px !important;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month,
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year {
    padding: 0px !important;
  }

  .p-datepicker table td>span {
    padding: 16px;
    font-size: 12px;
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
  }

  .p-datepicker table th>span {
    // padding: 7px;
    font-size: 14px;
    width: 1rem;
    height: 1rem;
    text-align: center;
  }

  .p-datepicker table td {
    padding: 0 !important;
  }
}

.figma-calender {
  .ng-invalid.ng-touched {
    .p-inputtext {
      border: 1px solid red !important;
    }

    &:focus {
      border-color: #764fdb;
    }
  }

  .ng-valid {
    border: none !important;
  }

  .p-button.p-button-icon-only {
    position: absolute;
    right: 16px;
    top: 1px;
  }

  .p-datepicker .p-datepicker-header {
    padding: 0px !important;
  }

  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month,
  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year {
    padding: 0px !important;
  }

  .p-datepicker table td>span {
    padding: 18px;
    font-size: 12px;
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
  }

  .p-datepicker table th {
    padding: 7px;
    font-size: 14px;
    width: 1rem;
    height: 1rem;
    text-align: center;
  }

  .p-datepicker-group-container {
    .pi-chevron-left:before {
      content: "⮜" !important;
    }

    .pi-chevron-right:before {
      content: "⮞" !important;
    }
  }

  .p-button {
    color: #ffffff;
    background: url(./assets/Images/calender-icon.svg) 100% no-repeat !important;
    border: 0 !important;
    background-color: #f5f5f5 !important;
    border-radius: 0 8px 8px 0 !important;

    &:focus {
      box-shadow: none !important;
    }
  }

  .p-calendar .p-datepicker {
    left: 0 !important;
    min-width: 300px;
    width: 300px;
  }

}

.figma_search_part {
  input {
    width: 438px;
    height: 40px;
    left: 704px;
    top: 24px;
    border: 1px solid #919292;
    border-radius: 8px;
    padding: 12px 30px 12px 46px !important;

    &:focus {
      outline: 0 !important;
    }
  }

  .figma__search__Icon {
    left: 14px;
    top: 12px;
  }
}

.background__wrapper {
  border-radius: 16px !important;
  background-color: #ffffff !important;
}

.figma__underLine {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #764fdb !important;
}

.figma__back__text {
  margin-left: 16px !important;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #2f3031;
}

.document_custom_styless {
  .resume_custom_droPZone {
    border: 1px dashed #764fdb !important;
    // background: #f5f5f5 !important;
    width: 100% !important;
  }

  ngx-dropzone-label {
    margin: 60px 500px !important;
  }

  .dropDownn__Spann {
    width: 120px !important;
  }

  .AddDocClass_twooo {
    height: 54px !important;
    width: 300px;
  }

  ngx-dropzone-image-preview {
    min-height: 30px !important;
    height: 52px;

    ngx-dropzone-label {
      display: flex !important;
      left: 0px !important;
      margin: 0 !important;

      .red_pdf_image {
        // margin-left: 60px !important;
        height: 32px;
        width: 32px;
        top: 4px;
        position: absolute;
      }

      .file__Size_drop {
        margin-left: -20px !important;
      }
    }

    ngx-dropzone-remove-badge svg {
      left: 290px !important;
      top: -2px;
    }
  }
}

.Lastcustom_droPZone_styles {
  .resume_custom_droPZone {
    border: 1px dashed #764fdb !important;
    background: #f5f5f5 !important;
    width: 100% !important;
  }

  .AddDocClass_twooo {
    height: 60px !important;
    overflow-y: hidden !important;
    border: 1px solid rgba(47, 48, 49, 0.1) !important;
    border-radius: 16px !important;
    width: 300px !important;

    ngx-dropzone-image-preview {
      // .red__pdff {
      //   position: absolute !important;
      //   left: -30px !important;
      // }
      min-height: 58px !important;
      margin-top: 48px !important;

      // height: 60px !important;
      // margin-top: 56px !important;
      // overflow-x: hidden !important;
      ngx-dropzone-label {
        margin-left: 160px !important;
      }

      ngx-dropzone-remove-badge {
        opacity: 1 !important;
        position: absolute;
        left: 234px;
        top: 60px;
      }
    }
  }
}

.job-searchh-new {
  right: 12px;
  top: 16px;
}

.applicant_main_kebab_menu {
  width: 223px;
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  right: 0;
  top: 0px !important;
  z-index: 1 !important;
  // height: 176vh;

  ul {
    padding: 8px 0 !important;

    li {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #2f3031;
      padding: 14px 16px;
      cursor: pointer;

      &:hover {
        background: #f4effe;
      }
    }
  }

  &:nth-last-child(1) {
    top: -66px;
  }
}

.comments_main {
  h1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #764fdb;
  }

  .comments_table {
    margin-top: 24px;

    .comments_table_heading {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #2f3031;
    }

    .comments_table_body_row {
      background: #ffffff;
      border: 1px solid #f4effe;

      td {
        padding: 34px 0px 34px 65px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #2f3031;
      }
    }

    .comments__lorem {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #2f3031;
    }

    tr {
      &:nth-child(1) {
        background: #f6f7fb;

        th {
          &:nth-child(1) {
            padding: 16px 0 16px 65px !important;
            border-radius: 16px 0 0 16px !important;
          }

          &:nth-last-child(1) {
            border-radius: 0 16px 16px 0 !important;
          }
        }
      }
    }
  }
}

// .dependent_outline {
//   .figma__ng__select .ng-select.ng-select-single .ng-select-container{
//     outline: 1px solid #b1afaf !important;
//   }
// }
.manage_input {
  font-size: 12px !important;
  border: 1px solid #ccc !important;
  line-height: 1.21428571em;
  padding: 0.67857143em 1em;
  box-shadow: 0 0 0 0 transparent inset;
  color: rgba(0, 0, 0, 0.87);
  padding: 8px 12px !important;

  &:focus {
    outline: 0 !important;
  }
}

input[type="text"] {
  font-family: "Noto Sans", sans-serif !important;
}

.job_rem_multiple {
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    margin-top: 2px !important;
  }
}

.p_font {
  font-size: 14px !important;
}

.p_fontt {
  font-size: 12px !important;
}

.pdf_custom_styles {
  .pdf_class_one {
    height: 63px;
    border: 0;
  }

  .pdf_class_two {
    height: 70px;
    width: 300px;
    border: 0 !important;

    ngx-dropzone-image-preview {
      min-height: 40px !important;
      height: 35px !important;
    }

    .myNew_Labell {
      .dropDownn__Spann {
        left: 60px;
        top: 10px;
        position: absolute;
      }
    }

    ngx-dropzone-remove-badge {
      opacity: 1 !important;
      left: 200px !important;
      background: transparent !important;

      svg {
        background: url(./assets/img/resume-cross-icon.svg) no-repeat !important;
        height: 20px !important;
        width: 15px !important;
        display: block !important;
        position: absolute;
        top: 10px;

        line {
          display: none !important;
        }
      }
    }
  }

  .bulk__UploadResume {
    padding: 10px 14px;
    margin-right: 6px;
    border: 0;
    border-radius: 8px;
  }
}

.ml_hundred {
  margin-left: 105px !important;
}

.phoneTexArea {
  margin-left: 88px;
}

.dependent_color_field {
  outline: 1px solid #764fdb !important;
  border-radius: 8px !important;
}

.app_calender {
  height: 24px;
  width: 24px;
}

.addApp_jobscore_partt {
  background: #f1edfb;
  border-radius: 8px;
  padding: 19px 24px;
  margin: 12px;

  .appJob_scoree {
    h5 {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #2f3031;

      span {
        font-weight: 500 !important;
        font-size: 12px !important;
      }
    }
  }

  .check_recruitment_Members {
    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #919292;
    }

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      text-decoration-line: underline;
      color: #7b61ff;
    }
  }
}

ng-select {
  .ng-select.ng-select-single .ng-select-container {
    z-index: 0 !important;
  }
}

checkbox {
  cursor: pointer !important;
}

.input_grey_color {
  color: #919292 !important;
}

.grey_ng_select .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  color: #919292 !important;
}

.light_red_color {
  color: rgba(207, 42, 42, 1) !important;
}

.fw400 {
  font-weight: 400 !important;
}

.fw500 {
  font-weight: 500 !important;
}

.fw600 {
  font-weight: 600 !important;
}

.fw700 {
  font-weight: 700 !important;
}

.fw800 {
  font-weight: 800 !important;
}

.t-w {
  color: white !important;
}

.fs12 {
  font-size: 12px !important;
}

.fs14 {
  font-size: 14px !important;
}

.fs16 {
  font-size: 16px !important;
}

.fs18 {
  font-size: 18px !important;
}

.fs20 {
  font-size: 20px !important;
}

.fs24 {
  font-size: 24px !important;
}

.fs26 {
  font-size: 26px !important;
}

.h_42 {
  height: 42px !important;
}

.brrr-0 {
  border-radius: 0 !important;
}

.p-20 {
  padding: 20px;
}

.submit_padding {
  padding: 12px 22px !important;
}

.brrr8 {
  border-radius: 8px !important;
}

.br---16 {
  border-radius: 16px !important;
}

.entity__TextArea {
  resize: none !important;

  &::placeholder {
    color: #999 !important;
  }
}

.fWhite {
  color: #ffffff !important;
}

.bgWhite {
  background: #ffffff !important;
}

.underlinee {
  text-decoration: underline !important;
}

.hhistory_border {
  border: 1px solid #f4effe;
  border-radius: 16px;
  padding: 24px;
}

.z-0 {
  z-index: 0 !important;
}

.z1 {
  z-index: 1 !important;
}

.z99 {
  z-index: 99 !important;
}

.z-999 {
  z-index: 999 !important;
}

.z9999 {
  z-index: 9999 !important;
}

.w100 {
  width: 100% !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.p2rem {
  padding: 2rem !important;
}

.styled-checkbox+label:before {
  content: "";
  border: 1px solid rgba(149, 105, 253, 1) !important;
  height: 20px !important;
  width: 20px !important;
  cursor: pointer;
  border-radius: 4px !important;
}

.styled-checkbox:checked+label:before {
  background: rgba(149, 105, 253, 1) !important;
  border-radius: 4px !important;
}

.styled-checkbox:checked+label:after {
  content: "";
  position: absolute !important;
  left: 8px !important;
  top: 6px !important;
  width: 5px !important;
  height: 15px !important;
  border: solid #fff !important;
  border-width: 0 2px 2px 0 !important;
  -webkit-transform: rotate(45deg) !important;
  -ms-transform: rotate(45deg) !important;
  transform: rotate(45deg) !important;
}

.dot_one {
  // background: #764fdb !important;
  top: 9px !important;
  left: -6px;
}

.stepper__dot {
  height: 10px;
  width: 10px;
  background-color: #bababb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  top: -8px;
}

// .timeLine__titles {
//   :nth-child(1) {
//     .popup_dots {
//       .square {
//         display: none !important;
//       }
//     }
//   }
// }
.timeLine__titles {
  &:first-child(1) {
    .stepper_Lineeee {
      border-left: 0;
    }
  }

  &:nth-last-child(1) {
    .stepper_Lineeee {
      border-left: 0;
    }
  }
}

.lh0 {
  line-height: 0px !important;
}

.square {
  height: 14px;
  width: 5px;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: -3px;
  // display: none;
}

.stepper_Lineeee {
  border-left: 2px solid #e4dcf8;
  width: 100%;
}

.progress_card_stepper_part {
  width: 100%;

  .stepper_Linee {
    // border-bottom: 1px solid #e4dcf8;
    margin-left: 40px;
    // height: 1px;
    // background: #E4DCF8;
  }

  .stepper__dot {
    height: 16px;
    width: 16px;
    background-color: #bababb;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    top: -8px;

    // left: 40px;
    &:nth-child(2) {
      left: 184px !important;
    }

    &:nth-child(3) {
      left: 364px !important;
    }

    &:nth-child(4) {
      left: 544px !important;
    }

    &:nth-child(5) {
      left: 724px !important;
    }

    &:nth-last-child(1) {
      left: 904px !important;
    }
  }
}

.ctc_limit_error {
  background: #fe905d;
  border-radius: 8px;
  padding: 14px 16px;
  width: fit-content !important;
}

.rejected_successfully {
  background: #04c054;
  border-radius: 8px;
  padding: 12px 16px;
  width: fit-content !important;
}

.giis_agree_checkbox__parrttt {
  .giis__checkk input:checked+label:after {
    top: 10px !important;
    left: 16px !important;
    width: 5px !important;
    height: 12px !important;
  }

  .giis__checkk label:before {
    padding: 7px !important;
    top: 5px;
    left: 9px;
  }
}

.giis_agree_checkbox__part {
  .giis__checkk {
    margin-top: -17px;
  }

  .giis__checkk input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  .giis__checkk label {
    position: relative;
    cursor: pointer;
  }

  .giis__checkk label:before {
    content: "";
    // -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #87898a;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 4px;
  }

  .giis__checkk input:checked+label:after {
    content: "";
    display: block;
    position: absolute;
    top: 4px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .giis__checkk input:checked+label:before {
    background-color: #764fdb;
  }
}

.sticky__Buttons {
  position: sticky !important;
  position: -webkit-sticky;
  height: fit-content;
  top: 70px !important;
  z-index: 9999 !important;
}

.new_figma_backgroundd {
  padding: 12px 24px !important;
  border-radius: 8px;
  width: 98% !important;
  box-shadow: 0 2px 9px 3px rgba(0, 0, 0, 0.04);
}

.aside__stickyy {
  position: sticky !important;
  top: 70px !important;
  z-index: 9999 !important;
}

.addApplicant__modal_main.resume-modal-main {
  .resume-modal-content {

    width: max-content !important;
    height: max-content !important;
    left: 50% !important;
    top: 100px !important;
    transform: translate(-50%);
  }
}

@media screen and (max-width: 1300px) {
  .addApplicant__modal_main.resume-modal-main {
    .resume-modal-content {
      left: 50% !important;
      top: 100px !important;
    }
  }
}

.addApplicant__modal_main {
  transition-delay: 2s;
  position: fixed;
  left: -10%;
  right: 0;
  top: -3rem !important;
  bottom: -5rem !important;
  background: rgb(0, 0, 0, 0.5);
  height: auto !important;
  min-height: 100vh !important;
  z-index: 999 !important;
  overflow-y: scroll;

  .add__App__btnn {
    padding: 10px 30px !important;
  }

  .addApplicant__mod__content {
    transition-delay: 2s;
    width: 414px;
    height: 234px;
    background: #ffffff;
    border-radius: 16px;
    padding: 32px 24px !important;
    position: absolute;
    left: 50% !important;
    top: 200px !important;
    transform: translate(-50%);
  }

  .blue__close__button {
    @include blueCloseButton();

    img {
      top: 17px !important;
      left: 17px !important;
    }
  }

  .add__app__heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 16px;
    color: #2f3031;
    margin-bottom: 56px !important;
  }

  .select_option_span {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.8);
  }

  .add__bulk__buttons {
    margin-top: 32px !important;
  }

  .add__App__btnn {
    padding: 12px 32px !important;
  }

  .figma__close {
    img {
      top: 18px;
      left: 16px;
    }
  }

  .bulK__Upload__btnn {
    padding: 12px 32px !important;
    margin-left: 20px !important;
  }
}

.p12-20 {
  padding: 12px 20px !important;
}

.p16 {
  padding: 16px !important;
}

.addJobsHiringTeam__table {
  .divTable__head {
    padding: 20px !important;

    .cell {
      color: #ffffff !important;
    }
  }

  .row__bodyy {
    padding: 20px !important;

    .cell {
      color: #ffffff !important;
    }
  }
}

.ng_select__disabled {
  .ng-select-disabled {
    .ng-select-container {
      cursor: not-allowed !important;
    }

    .ng-select .ng-select-container .ng-value-container .ng-input>input {
      cursor: not-allowed !important;
    }
  }
}

.cutoff__calender {
  .p-calendar {
    background: #f5f5f5 !important;
    border-radius: 8px !important;
  }
}

.addJobs-Main_text {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #764fdb;
  padding-bottom: 26px;
}

.all__kebab__menuu {
  width: 176px;
  padding: 4px;
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  right: -1px;
  top: 20px !important;
  z-index: 999 !important;

  ul {
    li {
      padding: 8px;
      color: #686868;
      font-size: 13px;
      font-weight: 500;
      padding: 12px;
      text-align: left;

      &:hover {
        background-color: #e4f7ff;
        border-radius: 10px;
      }

      img {
        margin-right: 6px;
      }
    }
  }
}

.jobss__brandd {
  margin-left: 2px;
  color: #fff;
  cursor: pointer;
  padding: 3px 5px;
  background: #764fdb !important;
  border-radius: 6px;
}

// input:-webkit-autofill{
// //  background: yellow !important;
// }
.o_y_scroll {
  overflow-y: scroll !important;
}

.interview__login__part_main {
  transition-delay: 2s;
  position: absolute;
  left: 0;
  right: 0;
  top: -3rem !important;
  bottom: -5rem !important;
  background: rgba(0, 0, 0, 0.5);
  height: auto !important;
  min-height: 100vh !important;

  .interview_login_popup {
    transition-delay: 2s;
    width: 414px;
    height: auto;
    background: #ffffff;
    border-radius: 16px;
    padding: 32px 24px !important;
    position: absolute;
    left: 40% !important;
    top: 200px !important;

    button {
      &.close {
        background: rgba(118, 79, 219, 0.2) !important;
        border-radius: 8px;
        height: 48px;
        width: 48px;
        padding: 15px;
        margin: -15px -15px -15px auto;

        img {
          top: 18px;
          left: 18px;
        }
      }

      &:focus {
        outline: 0 !important;
      }
    }
  }
}

.mapp__hiringg__stagee {
  ng-dropdown-panel {
    z-index: 99 !important;
  }
}

.tooltiptextedd {
  margin-top: 12px;
  margin-left: -140px;
  visibility: hidden;
  color: black;
  text-align: center;
  border-radius: 6px;
  // padding: 2px 2px 2px 2px;
  height: auto;
  font-size: 14px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  border: 1px solid black;
  width: max-content !important;
  background: #f6f7fb;
  padding: 10px;
  // right: 20px;
}

.tooltiptextedddd {
  background: #f6f7fb;
  padding: 10px;
  width: auto;
  height: auto;
  font-size: 14px;
  position: absolute;
  z-index: 1;
  color: black;
  text-align: center;
  border-radius: 6px;
  border: 1px solid black;
  margin-top: -32px !important;
}

.table__Header__Sticky {
  position: sticky;
  top: 0px !important;
  z-index: 1 !important;
}

.cursor_not_allowed {
  cursor: not-allowed !important;
}

.table__overflow {
  overflow-x: scroll !important;
}

.addJobs-Main_textt {
  font-size: 14px !important;
  font-weight: 700;
}

.jah_tooltip {
  .tooltip {
    position: relative;
    display: inline-block;
    opacity: 1 !important;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: #334155;
    color: #ffffff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 110%;
    // border: 1px solid black;
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 15%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    // border-color: black;
    // border: 5px solid black;
    border-color: transparent #334155 transparent transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .ttoooltipp {
    z-index: 1 !important;
  }
}

.field :disabled {
  opacity: 1 !important;
}

.float-right {
  float: right !important;
}

.accent-color {
  accent-color: #764fdb !important;
}

.newUpload_pdf {
  ngx-dropzone {
    &.emptyPDFClass {
      border: 0 !important;
      height: 50px !important;

      ngx-dropzone-label {
        margin: 0 !important;
      }
    }

    &.FillPDFClass {
      // border: transparent !important;
      overflow-y: hidden;
      border: 2px solid #f5f5f5 !important;
      border-radius: 8px !important;
      height: 56px !important;
      width: 234px;

      ngx-dropzone {
        min-height: 56px !important;
        height: 56px !important;
      }

      ngx-dropzone-label {
        margin-top: -10px !important;
        margin-bottom: 0px !important;
      }

      ngx-dropzone-preview {
        // background: url(./assets/img/red-pdf.svg) no-repeat !important;
        background: transparent !important;
        left: -30px;
        top: 5px;
        min-height: 56px !important;
        height: 56px !important;

        ngx-dropzone-remove-badge {
          opacity: 1 !important;
          background: transparent;

          svg {
            background: url(./assets/img/black-cross.svg) no-repeat;
            height: 18px;
            width: 18px;
            right: -66px;
            position: absolute;
            top: 10px;

            line {
              display: none;
            }
          }
        }
      }
    }
  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  z-index: 0 !important;
}

.reject_Reason_main {
  top: -3rem !important;
  bottom: -5rem !important;
  background: rgba(0, 0, 0, 0.5);
  // height: 120vh;
  min-height: 100vh !important;
  width: 110% !important;
  left: -10% !important;

  .reject_submit_btnn {
    padding: 16px 190px !important;
  }

  .reject_Reasonnn {
    padding: 26px 16px;
    background: #ffffff;
    border-radius: 16px;
    width: 470px;
    height: 400px;
    position: absolute;
    left: 50%;
    top: 100px;
    transform: translate(-50%, 0%);

    h1 {
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      color: #2f3031;
    }

    label {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #2f3031;
    }

    .rejected_Reason_TextArea {
      resize: none;
      background: #f5f5f5;
      border-radius: 8px;
      padding: 12px;
      border: 0;

      &::placeholder {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: rgb(47, 48, 49, 0.5);
      }

      &:focus {
        border: 0 !important;
        outline: 0 !important;
      }
    }
  }
}

ul.experiences li {
  position: relative;
  margin-bottom: 0;
  padding-bottom: 1em;
}

ul.experiences li:after {
  /* bullets */
  content: "";
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  background: #764fdb !important;
  position: absolute;
  left: 16px;
  top: 10px;
}

ul.experiences li:before {
  content: "";
  position: absolute;
  left: 20px;
  border-left: 2px solid #e4dcf8;
  height: 100%;
  width: 1px;
}

ul.experiences li:first-child:before {
  top: 10px;
}

ul.experiences li:last-child:before {
  height: 20px;
}

.int-add-button {
  padding: 12px 32px !important;
}

.view-app-overflow {
  overflow-y: auto;
  min-height: 60vh;
  height: 60vh;
  overflow-x: hidden;
}

.width-fit {
  width: fit-content !important;
}

.height-fit {
  height: fit-content !important;
}

.fous-border-transparent {
  &:focus {
    border: 1px solid #f5f5f5 !important;
    outline: 1px solid #f5f5f5 !important;
  }
}

button:disabled {
  cursor: not-allowed !important;
}

.back-white {
  background: white !important;
}

.paginationButton {
  &:focus {
    border: 1px solid white !important;
    outline: 1px solid white !important;
  }
}

.back-dim-white {
  background: rgba(210, 203, 203, 0.3) !important;
}

.fs40 {
  font-size: 40px !important;
}

.devInProgress {
  top: 50%;
  left: 40%;
  color: red !important;
}

.table-y-overflow {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  max-height: 700px !important;
  height: 700px !important;
}

.text-overflow {
  white-space: nowrap;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 768px) and (max-width: 1300px) {
    max-width: 90px !important;
  }
}

.text-overflow--220 {
  white-space: nowrap;
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: 768px) and (max-width: 1300px) {
    max-width: 90px !important;
  }
}

.tr-hover {
  &:hover {
    background: rgb(228, 220, 248) !important;
  }
}

.t70 {
  top: 70px !important;
}

.time_windw_inputs {
  .p-timepicker {
    .pi-chevron-up {
      transform: rotate(90deg);

      &::before {
        content: "⮜" !important;
      }
    }

    .pi-chevron-down {
      transform: rotate(270deg);

      &::before {
        content: "⮜" !important;
      }
    }
  }
}

.fs13 {
  font-size: 13px !important;
}

// .dots-button{
//   padding: 10px !important;
//   border-radius: 10px !important;
// }
.dots-button {
  background: transparent !important;
  border: 0 !important;
  outline: 0 !important;
}

.g--10 {
  gap: 10px !important;
}

.g20 {
  gap: 20px !important;
}

.width-max {
  width: max-content !important;
}

.pagination-buttons {
  border: 0 !important;
  outline: 0 !important;
  cursor: pointer;
  padding: 6px;
  background: rgba(118, 79, 219, 0.1) !important;
  border-radius: 8px;
}

.input-border-transparent {
  input {
    border: 0 !important;
    outline: 0 !important;
  }
}

.footer-pages-main {
  margin: 0 0 50px 0;

  .footer-pages-background {
    background: #101d36 !important;
    padding: 50px 0 50px 30%;
    justify-content: space-between !important;
    align-items: center !important;
    margin: 0 0 40px 0;

    h1 {
      color: #fff;
      font-size: 62px;
      font-weight: 600;
      line-height: 48px;
    }
  }

  &.mt-100 {
    margin-top: 100px;
  }

  .footer-data-list {
    margin-left: 30px;

    li {
      list-style-type: disc;
      line-height: 26px;
    }
  }

  p {
    line-height: 26px;
  }
}

.new-h-w {
  height: 30px !important;
  width: 30px !important;

  img {
    width: 12px !important;
    height: 12px !important;
  }
}

.notification-numberr {
  color: grey;
  font-size: 10px;
  position: absolute;
  left: 40%;
  top: 80%;
}

/* Change the color of the scrollbar track */
// ::-webkit-scrollbar {
//   width: 3px;
// }

/* Change the color of the scrollbar handle (thumb) */
// ::-webkit-scrollbar-thumb {
//   background-color: rgba(117, 77, 219, 0.8);
//   border-radius: 4px;
// }

/* Change the color of the scrollbar handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Change the color of the scrollbar track on hover */
::-webkit-scrollbar-track {
  background-color: #ecf0f1;
}

/* Change the color of the scrollbar corner */
::-webkit-scrollbar-corner {
  background-color: #ffffff;
}

.virtual_meeting_toggglee {
  input[type="checkbox"].switch_1 {
    font-size: 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 22px;
    height: 12px !important;
    background: white;
    outline: 2px solid #979898 !important;
    border-radius: 3em;
    position: relative;
    cursor: pointer;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  // input[type="checkbox"].switch_box:checked{
  // border: 2px sioid #9569FD !important;
  // }

  input[type="checkbox"].switch_1:after {
    position: absolute;
    content: "";
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background: #979898;
    -webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    left: 0;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  input[type="checkbox"].switch_1:checked {
    outline: 2px solid #9569fd !important;
  }

  input[type="checkbox"].switch_1:checked:after {
    left: calc(100% - 1.5em);
    background: #9569fd;
  }
}

.after-upload-pdf-part {
  padding: 10px 20px;
  border: 2px solid #f5f5f5;
  border-radius: 8px !important;
}

.font-black {
  color: #121926 !important;
}

.font-blue {
  color: rgb(21, 21, 246);
}

.opacity-60 {
  opacity: 0.6;
}

.font-grey {
  color: #4b5565;
}

.bg-grey {
  background-color: #efefef;
}

.font-black-3 {
  color: #2f3031 !important;
}

.mb18 {
  margin-block-end: 18px !important;
}

.brd16 {
  border-radius: 16px !important;
}

.font-black-4 {
  color: #424242 !important;
}

.font-black-5 {
  color: #364152 !important;
}

.figma_blue_brd_btn {
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  border: 1px solid #764fdb;
  text-transform: capitalize !important;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #764fdb;
  text-align: center !important;
  padding: 12px 24px;

  &:focus {
    outline: 0 !important;
  }

  // &:hover {
  //   color: white !important;
  // }
}

.text-italic {
  font-style: italic !important;
}

//ijp css

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.black {
  color: #000000;
}

.grey1 {
  color: #4b5565;
}

.blackk {
  color: #2f3031 !important;
}

.secondary-black {
  color: #121926;
}

.fs10 {
  font-size: 10px !important;
}

.fs12 {
  font-size: 12px !important;
}

.fs14 {
  font-size: 14px !important;
}

.fs16 {
  font-size: 16px !important;
}

.fs18 {
  font-size: 18px !important;
}

.fs20 {
  font-size: 20px !important;
}

.fs22 {
  font-size: 22px !important;
}

.fs24 {
  font-size: 24px !important;
}

.fs26 {
  font-size: 26px !important;
}

.fs28 {
  font-size: 28px !important;
}

.fs30 {
  font-size: 30px !important;
}

.fs32 {
  font-size: 32px !important;
}

.fs34 {
  font-size: 34px !important;
}

.fs36 {
  font-size: 36px !important;
}

.fs38 {
  font-size: 38px !important;
}

.fs40 {
  font-size: 40px !important;
}

.fw400 {
  font-weight: 400 !important;
}

.fw500 {
  font-weight: 500 !important;
}

.fw600 {
  font-weight: 600 !important;
}

.fw700 {
  font-weight: 700 !important;
}

.fw800 {
  font-weight: 800 !important;
}

.fw900 {
  font-weight: 900 !important;
}

.pointer {
  cursor: pointer !important;
}

.gap10 {
  gap: 10px !important;
}

.gap12 {
  gap: 12px !important;
}

.gap16 {
  gap: 16px !important;
}

.gap30 {
  gap: 30px !important;
}

.line {
  border-bottom: 1px solid #dadee7;
  margin-top: 16px;
  margin-bottom: 16px;
}

.line2 {
  border-bottom: 1px solid #f5f5f5;
  margin-top: 16px;
  margin-bottom: 16px;
}

.verticalline {
  background: #e2e2e2;
  width: 1px;
  height: 20px;
  margin-inline-start: 16px;
  margin-inline-end: 16px;
}

.verticalline2 {
  background: #E3E8EF;
  width: 1px;
  height: 12px;
  margin-inline-start: 16px;
  margin-inline-end: 16px;
}

.body-main {
  padding: 24px;
  background: white !important;
  border-radius: 16px;
}

.back-btn {
  button {
    margin-right: 16px;
    padding: 10px 10px 8px 10px;
    border-radius: 8px;
    background: rgba(118, 79, 219, 0.1);
    border: 0;
    outline: 0;
  }

  h1 {
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    color: #2f3031;
  }

  @media screen and (max-width: 767px) {
    button {
      padding: 5px 5px 4px 5px;
    }

    h1 {
      font-size: 10px;
      font-weight: 700;
      line-height: 26px;
      color: #2f3031;
    }
  }

  @media (min-width: 768px) and (max-width: 1300px) {
    h1 {
      font-size: 14px;
      font-weight: 700;
      line-height: 26px;
      color: #2f3031;
    }
  }
}

.grey-button {
  button {
    padding: 8px 16px;
    background: #e2e2e2;
    border-radius: 8px;
    border: 0;
    outline: 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: #9c9d9d;
  }
}

.h--32 {
  height: 32px !important;
}

.small-button {
  button {
    border-radius: 8px;
    padding: 5px;
    border: 1.5px solid #764fdb;
    background: transparent;
  }
}

.blue-color {
  color: #976ef7 !important;
}

.blue-background {
  background: #976ef7 !important;
}

.white-background {
  background: #ffffff !important;
}

.p16 {
  padding: 16px !important;
}

.br8 {
  border-radius: 8px !important;
}

.br12 {
  border-radius: 12px !important;
}

.w90 {
  width: 90% !important;
}

.w85 {
  width: 85% !important;
}

.figma-input {
  label {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #2f3031;
  }

  input {
    margin-top: 6px;
    border-radius: 8px;
    background: #f5f5f5;
    border: 0;
    outline: 0;
    padding: 12px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #2f3031;

    &::placeholder {
      color: #9c9d9d;
      font-weight: 400;
    }
  }
}

.figma-ng-select {
  .ng-select {

    &.invalid,
    &.ng-touched {
      border: 1px solid red !important;
      border-radius: 8px !important;

      &:focus {
        border-color: #764fdb;
      }
    }

    &.ng-valid {
      border: none !important;
    }
  }

  label {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #2f3031;
    margin-bottom: 6px;
  }

  .ng-select .ng-select-container {
    background: #f5f5f5;
    border: 0;
    outline: 0;
    height: 40px;
    padding: 12px;
    border-radius: 8px;
    // border-top-right-radius: 0px;
    // border-bottom-right-radius: 0px;
  }

  .ng-arrow-wrapper {
    background: url(./assets/Images/select-icon.svg) no-repeat !important;
    transform: rotate(180deg);
  }

  .ng-select .ng-arrow-wrapper .ng-arrow {
    border-color: transparent !important;
    border-style: none !important;
    border-width: 0 !important;
  }

  .ng-select .ng-arrow-wrapper {
    width: 25px;
    top: -4px;
    // margin-right: 20px;
  }

  // .ng-select.ng-select-opened>.ng-select-container
  .ng-select.ng-select-opened>.ng-select-container .ng-arrow {
    background: url(./assets/Images/select-icon.svg) no-repeat !important;
    transform: rotate(0deg) !important;
    right: 5px !important;
  }

  .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    box-shadow: none !important;
    border-color: none !important;
  }

  .ng-select .ng-clear-wrapper {
    display: none !important;
  }

  .ng-select .ng-select-container .ng-value-container .ng-input>input {
    caret-color: transparent !important;
  }

  .ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: #9c9d9d;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .ng-select .ng-select-container .ng-value-container {
    padding-left: 0;
  }
}

.figma-calender {
  .primecalendar {
    .ng-invalid.ng-touched {
      .p-inputtext {
        border: 1px solid red !important;
      }

      &:focus {
        border-color: #764fdb;
      }
    }

    &.ng-valid {
      border: none !important;
    }
  }

  label {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #2f3031;
    margin-bottom: 6px;
  }

  .p-calendar {
    height: 44px !important;
    width: 100% !important;
  }

  .p-button {
    background-color: #f5f5f5 !important;
    border-radius: 0 8px 8px 0 !important;
    border: 0 !important;
  }

  .p-calendar-w-btn .p-inputtext {
    border: 0 !important;
    background: #f5f5f5 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    border-radius: 8px 8px 8px 8px !important;

    &::placeholder {
      font-size: 14px !important;
      font-weight: 400 !important;
      color: #9c9d9d !important;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .p-element {
    .p-icon-wrapper {
      background: url(./assets/Images/calender-icon.svg) no-repeat !important;
      height: 20px !important;
      right: 20% !important;
      transform: rotate(0deg) !important;
    }

    .p-ripple.p-element.p-datepicker-prev {
      .p-icon-wrapper {
        background: transparent !important;

        svg {
          display: none;
        }

        &:before {
          content: "⮜" !important;
        }
      }
    }

    .p-ripple.p-element.p-datepicker-next {
      .p-icon-wrapper {
        background: transparent !important;

        svg {
          display: none;
        }

        &:before {
          content: "⮞" !important;
        }
      }
    }

    // .p-datepicker .p-datepicker-header .p-datepicker-prev {
    // }

    // .p-datepicker .p-datepicker-header .p-datepicker-next {
    // }
  }

  .p-calendar .p-datepicker {
    left: -50px;
    z-index: 99 !important;
  }

  .p-datepicker table td {
    padding: 0px !important;
  }
}

.underline {
  text-decoration: underline !important;
}

.blue-btn {
  background: #764fdb;
  padding: 8px 50px;
  border: 1.5px solid #764fdb;
  outline: 0;
  color: white;
  font-size: 14px !important;
  font-weight: 600;
  border-radius: 8px;
  text-transform: capitalize;
  text-wrap: nowrap;
  cursor: pointer;
  text-transform: capitalize;

  @media screen and (max-width: 1300px) {
    padding: 5px 30px;
    font-size: 12px;
  }
}

.grey-btn {
  background: #ffffff;
  padding: 8px 50px;
  border: 1.5px solid #E3E8EF;
  outline: 0;
  color: #4B5565;
  font-size: 14px !important;
  font-weight: 600;
  border-radius: 8px;
  text-transform: capitalize;
  text-wrap: nowrap;
  cursor: pointer;
  text-transform: capitalize;

  @media screen and (max-width: 1300px) {
    padding: 5px 30px;
    font-size: 12px;
  }
}

.secondary-btn {
  text-transform: capitalize;
  padding: 8px 50px;
  border-radius: 8px;
  border: 1.5px solid #764fdb;
  color: #764fdb;
  font-size: 14px !important;
  font-weight: 600;
  background: transparent;
  text-transform: capitalize;
  text-wrap: nowrap;
  cursor: pointer;

  @media screen and (max-width: 1300px) {
    padding: 5px 30px;
    font-size: 12px;
  }
}

.basic-info-line-part {
  h2 {
    border-right: 2px solid #e2e2e2;
    padding-right: 20px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: #69696a;
  }
}

.job-id-part {
  img {
    padding-left: 20px;
    padding-right: 8px;
  }

  h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: #69696a;
  }
}

.grey {
  color: #8793ab !important;
}

.job-info-inputs {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 20px !important;
}

@media (min-width: 1024px) and (max-width: 1300px) {
  .job-info-inputs {
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  }
}

.table.figma-table {
  thead {
    tr {
      th {
        &:nth-child(1) {
          border-radius: 8px 0 0 8px;
        }

        &:nth-last-child(1) {
          border-radius: 0 8px 8px 0;
        }
      }
    }
  }

  tbody {
    tr {
      margin-top: 20px;

      td {
        vertical-align: middle;
      }
    }
  }
}

.figma-modal {
  .p-dialog .p-dialog-header .p-dialog-title {
    font-weight: 600 !important;
    font-size: 16px !important;
  }

  .p-dialog-header {
    background: #f6f7fb !important;

    .p-icon-wrapper {
      right: 10% !important;
      top: -2px !important;
      height: 30px !important;
      width: 30px !important;
      background: url(./assets/Images/close-icon.svg) no-repeat !important;
    }
  }

  .p-dialog .p-dialog-content {
    background: #f6f7fb !important;
  }

  .p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
    border-radius: 4px !important;
    padding: 8px !important;
    background-color: #764fdb1a !important;
  }
}

.three-dots-button {
  background-color: #f2f2ff !important;
  padding: 8px 10px !important;
  border: 0;
  outline: 0 !important;
  border-radius: 10px;
  cursor: pointer;

  img {
    height: 12px !important;

    @media only screen and (max-width: 1300px) {
      height: 10px !important;
    }
  }

  @media only screen and (max-width: 1300px) {
    padding: 6px 8px;
  }
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.search-inputt {
  input {
    border: 1px solid #cdd5df;
    border-radius: 8px;
    padding: 8px 12px 8px 40px;
    outline: 0;

    ::placeholder {
      font-weight: 400;
      font-size: 16px;
      color: #9aa3b2;
    }
  }

  img {
    top: 10px;
    left: 10px;
  }

  .cross-iconn {
    position: absolute;
    right: 0.5rem;
    top: 0.6em;
  }
}

.filters-button {
  border-radius: 8px;
  padding: 8px 12px;
  border: 1px solid #cdd5df;
}

.filter-badge {
  padding: 5px 8px;
  border-radius: 50%;
  font-weight: 500;
  font-size: 12px;
  color: #764fdb;
  background: #f2f2ff;
}

.transparent-button {
  padding: 10px 20px;
  border: 1.2px solid #cdd5df;
  font-weight: 600;
  font-size: 14px;
  color: #cdd5df;
  outline: 0;
  border-radius: 8px;
}

.table-row-down-card {
  background: #f2f2ff;
  padding: 12px;

  .first-part {
    p {
      font-weight: 500;
      font-size: 12px;
      color: #4b5565;
    }
  }
}

.figma-calender svg path {
  display: none !important;
}

// .create-job-select.figma-ng-select .ng-select .ng-select-container{
//   background: transparent !important;
// }
button:disabled {
  cursor: not-allowed !important;
}

.error {
  color: red !important;
  font-size: 14px !important;
}

.custom-loader {
  position: absolute;
  // display: grid;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  opacity: 0.5;
  animation: blink-animation 1s ease-in-out infinite alternate;

  img {
    width: 100%;
    height: 100%;
  }
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.1;
  }
}

@keyframes s3 {
  100% {
    transform: rotate(1turn);
  }
}

strong {
  font-weight: bold !important;
}

em {
  font-style: italic !important;
}

.errorMessage {
  font-size: 12px;
  color: red;
}

.width-max {
  width: max-content !important;
}

.number-of-applicants {
  position: absolute !important;
  top: 54% !important;
  left: 6% !important;
  transform: translate(-50%, -50%) rotate(270deg) !important;
  transform-origin: 0 0 !important;
  padding: 0 !important;
  margin: 0 !important;

  @media only screen and (min-width: 1100px) and (max-width: 1300px) {
    left: 7% !important;
  }

  @media (min-width: 900px) and (max-width: 1024px) {
    left: 8% !important;
  }

  @media (min-width: 800px) and (max-width: 900px) {
    left: 10% !important;
  }
}

.max-heightt {
  height: max-content !important;
}

.p-30-60 {
  padding: 60px 10px;
}

.faded-grey {
  color: #bababb;
}

.grey-add-btn {
  background: #e2e2e2;
  padding: 10px 60px;
  border: none;
  outline: 0;
  color: #9c9d9d;
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px;
  cursor: pointer;
}

.primary-border {
  border: 1.5px solid #764fdb !important;
  border-radius: 8px !important;
}

.mb16 {
  margin-bottom: 16px !important;
}

.blue-brd-pill {
  border-radius: 6px;
  border: 1px solid #976ef7;
  background: #fff;
  padding: 6px 8px;
  width: fit-content;
  display: flex;

  img {
    cursor: pointer;
  }
}

.pointer {
  cursor: pointer !important;
}

input[type="text"]:read-only {
  cursor: not-allowed !important;
}

.ll-hh-20 {
  line-height: 20px !important;
}

.my-textarea {
  label {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #2f3031;
    margin-bottom: 6px;
    width: max-content;
    position: relative;

    sup {
      position: absolute;
      top: -3px;
      right: -10px;
      color: red;
    }
  }

  textarea {
    padding: 12px !important;
    background: #f5f5f5 !important;
    border-radius: 8px;
    border: transparent !important;
    resize: none;
    font-size: 14px !important;
    outline: 0;
    width: 100%;
    margin-bottom: 10px;
  }
}

.light-blue-bg {
  background-color: #f2f2ff !important;

}

sup {
  font-size: 14px;
  color: red;
}

.clear-btn {
  background: transparent;
  padding: 8px;
  border: 1.5px solid red;
  outline: 0;
  color: red;
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px;
  cursor: pointer;
}

.red-bg-btn {
  background: red;
  padding: 8px;
  outline: 0;
  border: 0px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px;
  cursor: pointer;
}

.font-black {
  color: #121926;
}

.no-recruitment-members {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loaderrrr {
  height: 20px;
  width: 250px;
  margin: auto;
}

.loader--text {
  width: 7rem;
  color: #764fdb;
  margin: auto;
}

.loader--text:after {
  content: "Loading";
  font-weight: normal;
  animation-name: loading-text;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes loading-text {
  0% {
    content: "Loading";
  }

  25% {
    content: "Loading.";
  }

  50% {
    content: "Loading..";
  }

  75% {
    content: "Loading...";
  }
}

.pi-calendar:before {
  content: "" !important;
}

button,
input {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

::-webkit-scrollbar {
  height: 4px !important;
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136, 0.5) !important;
  border-radius: 6px !important;

  &:hover {
    background: rgb(136, 136, 136, 0.5) !important;
  }
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.blue-txt-btn {
  background-color: transparent;
  border: none;
  outline: none;
  color: #764fdb !important;
  text-wrap: nowrap !important;

  &:focus {
    outline: none;
    box-shadow: none;
    border: none;
  }
}

.text-red {
  color: #eb5146;
}

.blue-patch {
  background: #f1f5fa;
  color: #121926;
  padding: 2px 6px 2px 8px;
  border-radius: 6px;
  border: 0.5px solid #e3e8ef;
  font-size: 14px;
  // margin-inline-end: 8px;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.my-kebab-menu {
  padding: 6px;
  position: absolute;
  box-shadow: 0px 4px 12px -2px #10182814;
  background: white;
  width: max-content;
  right: 10px;
  top: 0px;
  border-radius: 8px;
  z-index: 9999;

  ul {
    li {
      border-radius: 8px;
      padding: 12px;
      font-weight: 400;
      font-size: 13px;
      color: #121926;

      &:hover {
        background: #e4f7ff;
        cursor: pointer;
      }

      button {
        border-radius: 8px;
        padding: 0px;
        font-weight: 500;
        font-size: 16px;
        color: #121926;
        border: none !important;
        background: transparent;

        &:hover {
          background: #e4f7ff;
          cursor: pointer;
        }
      }
    }
  }
}

.form-control,
textarea {
  &.ng-invalid.ng-touched {
    border: 1px solid red !important;

    &:focus {
      border-color: #764fdb;
    }
  }

  &.ng-valid {
    border: none !important;
  }
}

.new_cal_styles {
  &.invalid {
    .p-inputtext {
      border: 1px solid red;
    }

    &:focus {
      border-color: #764fdb;
    }
  }

  &.ng-valid {
    border: none;
  }
}

.time_windw_inputs {
  .figma__input__styles {
    &.ng-invalid.ng-touched {
      .p-inputtext {
        border: 1px solid red !important;
      }

      &:focus {
        border-color: #764fdb;
      }
    }

    &.ng-valid {
      .p-inputtext {
        border: none !important;
      }
    }
  }
}

.my-custom-tooltip {
  position: relative;
  display: flex;
  cursor: pointer;

  .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: black;
    color: #fff;
    // text-align: center;
    border-radius: 6px;
    padding: 6px 10px;
    position: absolute;
    z-index: 1;
    top: 125%;
    left: 50%;
    margin-left: -70px;
    opacity: 0;
    transition: opacity 0.3s;

    &::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 24%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent black transparent;
    }
  }

  &:hover {
    .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
  }

  .my-tooltip-table {
    &:last-child {
      hr {
        display: none;
      }
    }
  }
}

.box-shadow {
  box-shadow: 0px -1px 12px -2px #10182814;
}

.light-blue-bg-new {
  background-color: #F1F5FA;
}

.figma-calender {
  .p-calendar .p-datepicker {
    min-width: fit-content !important;
    width: 200px !important;
  }

  .p-calendar-timeonly {
    .p-button {
      background: url(./assets/Images/select-down-arrow.svg) 100% no-repeat !important;
    }

    .p-timepicker {
      .pi-chevron-up {
        transform: rotate(90deg);

        &::before {
          content: "⮜" !important;
        }
      }

      .pi-chevron-down {
        transform: rotate(270deg);

        &::before {
          content: "⮜" !important;
        }
      }
    }
  }
}

.grey-text-4 {
  color: #697586 !important;
}

.opacity-half {
  opacity: 0.5;
  pointer-events: none;
}

.small-loader {
  width: 28px;
  height: 28px;
  border: 3px solid #764fdb;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.supported-file-message{
  font-size: 10px !important;
  color: red !important;
}
